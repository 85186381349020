import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import Web3Status from 'components/Web3Status'
// import { chainIdToBackendName } from 'graphql/data/util'
// import { useDisableNFTRoutes } from 'hooks/useDisableNFTRoutes'
// import { useIsLandingPage } from 'hooks/useIsLandingPage'
import { useIsNftPage } from 'hooks/useIsNftPage'
import { Box } from 'nft/components/Box'
import { Row } from 'nft/components/Flex'
// import { useProfilePageState } from 'nft/hooks'
// import { ProfilePageStateType } from 'nft/types'
// import { GetTheAppButton } from 'pages/Landing/components/DownloadApp/GetTheAppButton'
import { useCallback } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { useAccountDrawer } from 'components/AccountDrawer/MiniPortfolio/hooks'
import { Z_INDEX } from 'theme/zIndex'
// import { Chain } from 'uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks'
import { useIsNavSearchInputVisible } from '../../nft/hooks/useIsNavSearchInputVisible'
// import { Bag } from './Bag'
import Blur from './Blur'
import { ChainSelector } from './ChainSelector'
// import { More } from './More'
// import { SearchBar } from './SearchBar'
import clsx from 'clsx'
import { HoldstationIcon } from 'components/Logo/HoldstationIcon'
import * as styles from './style.css'

const Nav = styled.nav`
  padding: ${({ theme }) => `${theme.navVerticalPad}px 12px`};
  width: 100%;
  height: ${({ theme }) => theme.navHeight}px;
  z-index: ${Z_INDEX.sticky};
`

export const PageTabs = () => {
  const { pathname } = useLocation()

  if (pathname.includes('bridge')) {
    return null
  }

  return (
    <div className="flex items-center p-[2px]">
      <NavLink
        className={({ isActive }) => {
          return clsx(
            'text-md md:text-sm font-medium md:font-normal tracking-[-0.32px] px-[20px] py-[10px] md:rounded-full pb-[12px] md:border-none',
            {
              'text-white bg-black border-b border-white ': isActive,
              'text-[#808080] border-b border-black': !isActive,
            }
          )
        }}
        to="/swap"
      >
        <Trans>Swap</Trans>
      </NavLink>

      <NavLink
        className={({ isActive }) => {
          return clsx(
            'text-md md:text-sm font-medium md:font-normal tracking-[-0.32px] px-[20px] py-[10px] md:rounded-full md:border-none',
            {
              'text-white bg-black border-b border-white':
                isActive || pathname.includes('/add') || pathname.includes('detail') || pathname.includes('remove'),
              'text-[#808080] border-b border-black': !isActive,
            }
          )
        }}
        to="/pool"
      >
        <Trans>Pool</Trans>
      </NavLink>
    </div>
  )
}

const Navbar = ({ blur }: { blur: boolean }) => {
  const isNftPage = useIsNftPage()
  // const isLandingPage = useIsLandingPage()
  // const sellPageState = useProfilePageState((state) => state.state)
  const navigate = useNavigate()
  const isNavSearchInputVisible = useIsNavSearchInputVisible()

  const { account } = useWeb3React()
  const [accountDrawerOpen, toggleAccountDrawer] = useAccountDrawer()
  const handleUniIconClick = useCallback(() => {
    if (account) {
      return
    }
    if (accountDrawerOpen) {
      toggleAccountDrawer()
    }
    navigate({
      pathname: '/',
      search: '?intro=true',
    })
  }, [account, accountDrawerOpen, navigate, toggleAccountDrawer])

  return (
    <>
      {blur && <Blur />}
      <Nav>
        <Box display="flex" height="full" flexWrap="nowrap">
          <Box className="flex md:flex-1 items-center">
            <Box className={styles.logoContainer}>
              <HoldstationIcon />
            </Box>

            {!isNftPage && (
              <Box display={{ sm: 'flex', lg: 'none' }}>
                <ChainSelector leftAlign={true} />
              </Box>
            )}

            <div className="hidden md:block absolute left-1/2 -translate-x-1/2 bg-[#171717] rounded-full">
              <PageTabs />
            </div>
          </Box>

          <Box className="flex flex-1 items-center justify-end">
            {/* <Point /> */}

            <Row gap="12">
              {/*{isNftPage && sellPageState !== ProfilePageStateType.LISTING && <Bag />}*/}
              {!isNftPage && (
                <Box display={{ sm: 'none', lg: 'flex' }}>
                  <ChainSelector />
                </Box>
              )}
              {/*{isLandingPage && <GetTheAppButton />}*/}
              <Web3Status />
            </Row>
          </Box>
        </Box>
      </Nav>
    </>
  )
}

export default Navbar
