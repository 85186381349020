import dropdown from 'assets/images/dropdown_v2.svg'
import hold from 'assets/svg/hold.svg'
import time from 'assets/svg/time.svg'
import { useEstimateGas } from './data'

export function EstimateReceive() {
  const { data, isLoading } = useEstimateGas()

  // Loading
  // if (true) {
  //   return (
  //     <div className="flex items-center justify-center gap-1 h-[58px]">
  //       <LoadingV2 loadingColor="white" className="!w-[14px] !h-[14px] [&>div]:w-[14px] [&>div]:h-[14px]" />

  //       <p className="text-xs text-[#808080]">Loading</p>
  //     </div>
  //   )
  // }

  return (
    <div className="p-5 bg-[#141414] rounded-2xl flex flex-col gap-3">
      <div className="flex flex-col gap-4">
        <h2 className="text-white text-base font-medium">Receive on Berachain</h2>

        <div className="flex justify-between items-center">
          <div className="flex items-center gap-4">
            <div className="w-10 h-10">
              <img src={hold} className="w-full h-full object-cover" alt="Zksync" width={40} height={40} />
            </div>

            <div className="flex flex-col">
              <div className="text-white text-2xl font-medium">12</div>
              <div className="text-[#808080] text-xs font-medium leading-[18px]">~ $38,441.76</div>
            </div>
          </div>

          <div className="px-2 h-7 bg-[#1f1f1f] rounded-full flex items-center gap-1.5">
            <div className="w-4 h-4 relative  overflow-hidden">
              <img src={time} alt="time" width={16} height={16} loading="lazy" />
            </div>

            <div className="justify-start items-center gap-1 flex">
              <p className="text-[#808080] text-sm font-normal leading-tight">Est</p>
              <p className="text-white text-sm font-medium leading-tight">4m</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex justify-between items-center">
          <p className="text-[#b2b2b2] text-sm">Withholding Fee</p>
          <p className="text-white text-sm">0.001</p>
        </div>

        <div className="flex justify-between items-center">
          <p className="text-[#b2b2b2] text-sm">Est. Receive Amount</p>
          <p className="text-white text-sm">12 HOLD</p>
        </div>
      </div>

      <div className="bg-[#1f1f1f] w-full h-[1px]" />

      <div className="flex justify-between items-center">
        <p className="text-[#b2b2b2] text-sm">Est. Gas</p>

        <div className="pl-3 pr-2 py-1 bg-[#1f1f1f] rounded-full flex items-center gap-1.5">
          <p className="text-white text-base font-medium">0.002</p>

          <div className="w-4 h-4">
            <img src={hold} className="w-full h-full object-cover" alt="hold" width={40} height={40} />
          </div>

          <div className="w-4 h-4">
            <img src={dropdown} className="w-full h-full object-cover" alt="dropdown" width={20} height={20} />
          </div>
        </div>
      </div>
    </div>
  )
}
