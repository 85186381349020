import { EndpointId } from '@layerzerolabs/lz-definitions'
import { addressToBytes32, Options } from '@layerzerolabs/lz-v2-utilities'
import { BigNumberish, BytesLike, ethers } from 'ethers'
import { Erc20__factory } from 'uniswap/src/abis/types'
import { oftAbi } from './constant'

interface SendParam {
  dstEid: EndpointId // Destination endpoint ID, represented as a number.
  to: BytesLike // Recipient address, represented as bytes.
  amountLD: BigNumberish // Amount to send in local decimals.
  minAmountLD: BigNumberish // Minimum amount to send in local decimals.
  extraOptions: BytesLike // Additional options supplied by the caller to be used in the LayerZero message.
  composeMsg: BytesLike // The composed message for the send() operation.
  oftCmd: BytesLike // The OFT command to be executed, unused in default OFT implementations.
}

// send tokens from a contract on one network to another

export async function bridgeHold({
  oftAddress,
  toAddress,
  dstEid,
  signer,
  amountHold,
  fee,
}: {
  oftAddress: string
  toAddress: string
  dstEid: number
  signer: any
  amountHold: string
  fee: number
}) {
  // Get the contract factories
  // Create contract instances

  const oftContract = new ethers.Contract(oftAddress, oftAbi, signer)

  const amount = ethers.utils.parseUnits(amountHold, 18)
  const options = Options.newOptions().addExecutorLzReceiveOption(150000, 0).toBytes()

  // Now you can interact with the correct contract
  const oft = oftContract

  const minAmountLD = amount.sub(amount.mul(fee).div(1000))
  // zk -> bera: 80000, bera -> zk, 324
  const sendParam: SendParam = {
    dstEid, // bera: 40346 , zk : 30165
    to: addressToBytes32(toAddress), // address cua minh
    amountLD: amount, //
    minAmountLD,
    extraOptions: options,
    composeMsg: ethers.utils.arrayify('0x'), // Assuming no composed message
    oftCmd: ethers.utils.arrayify('0x'), // Assuming no OFT command is needed
  }
  console.log(56, sendParam)
  // Get the quote for the send operation
  const feeQuote = await oft.quoteSend(sendParam, false) // lay gas fee tu day
  console.log(59, feeQuote)
  const nativeFee = feeQuote.nativeFee

  // // If the token address !== address(this), then this is an OFT Adapter
  // if (innerTokenAddress !== oft.address) {
  //     // If the contract is OFT Adapter, get decimals from the inner token
  //     const innerToken = ERC20Factory.attach(innerTokenAddress);

  //     // Approve the amount to be spent by the oft contract
  //     await innerToken.approve(oftDeployment.address, amount);
  // }

  const r = await oft.send(sendParam, { nativeFee, lzTokenFee: 0 }, toAddress, {
    value: nativeFee,
  })
  console.log(`Send tx initiated. See: https://layerzeroscan.com/tx/${r.hash}`)
  return r
}

export async function approve({
  spender,
  tokenAddress,
  signer,
}: {
  spender: string
  tokenAddress: string
  signer: any
}) {
  const erc20Contract = Erc20__factory.connect(tokenAddress, signer)

  const r = await erc20Contract.approve(spender, ethers.constants.MaxUint256)
  console.log(`Send tx initiated. See: https://layerzeroscan.com/tx/${r.hash}`)
  return r
}
