import { useWeb3React } from '@web3-react/core'
import success from 'assets/svg/success.svg'
import BigNumber from 'bignumber.js'
import { useToggleAccountDrawer } from 'components/AccountDrawer/MiniPortfolio/hooks'
import { ButtonV3 } from 'components/Button/ButtonV3'
import { useCallback, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { toast } from 'sonner'
import { BrigeForm } from './bridge'
import { BERA_OFT, ZKSYNC_OFT } from './constant'
import { useBalance } from './data'
import { approve, bridgeHold } from './utils'

export function Button() {
  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = useFormContext<BrigeForm>()
  const { account } = useWeb3React()
  const toggleWalletDrawer = useToggleAccountDrawer()
  const { provider, chainId } = useWeb3React()
  // const toChainId = watch('to.chainId')

  const amount = useWatch({ control, name: 'value' })

  const { data, refetch } = useBalance()
  console.log('data', data)
  const isNeedApprove = useMemo(() => {
    if (!data?.[1]) {
      return false
    }

    if (new BigNumber(amount).multipliedBy(1e18).lt(data[1].toString())) {
      return true
    }

    return true
  }, [amount, data])

  const approveHoldBridge = useCallback(async () => {
    if (!provider || !chainId) {
      return
    }

    try {
      let oft = ZKSYNC_OFT.oft
      let hold = ZKSYNC_OFT.hold
      // let eid = BERA_OFT.eid
      // zk_sepolia
      if (chainId === 80000) {
        oft = BERA_OFT.oft
        hold = BERA_OFT.hold
        // eid = ZKSYNC_OFT.eid
      }

      const tx = await approve({
        spender: oft,
        tokenAddress: hold,
        signer: provider.getSigner(),
      })
      const receipt = await tx.wait()
      if (receipt.status) {
        console.log('success approve', receipt.transactionHash)

        toast(() => {
          return (
            <div className="flex flex-col gap-3">
              <img src={success} alt="success" className="w-7 h-7" />
              <p className="text-white text-base font-medium">Approve Successful</p>
            </div>
          )
        })

        refetch()
        return
      }

      console.log('fail approve')
    } catch (error) {
      console.log('Error')

      toast('Approve Failed')
    }
  }, [chainId, provider, refetch])

  const bridgeCall = useCallback(async () => {
    if (!provider || !chainId) {
      return
    }

    try {
      let oft = ZKSYNC_OFT.oft
      let eid = BERA_OFT.eid
      // zk_sepolia
      if (chainId === 80000) {
        oft = BERA_OFT.oft
        eid = ZKSYNC_OFT.eid
      }
      const signer = provider.getSigner()
      const address = await signer.getAddress()
      const tx = await bridgeHold({
        oftAddress: oft,
        toAddress: address,
        dstEid: eid,
        signer,
        amountHold: amount,
        fee: 2, // fee 0.2/100 -> sau call contract
      })
      const receipt = await tx.wait()
      if (receipt.status) {
        console.log('success bridge', receipt.transactionHash)

        toast(() => {
          return (
            <div className="flex flex-col gap-3">
              <img src={success} alt="success" className="w-7 h-7" />
              <p className="text-white text-base font-medium">Bridge Successful</p>
            </div>
          )
        })

        refetch()
        return
      }
    } catch (error) {
      console.log('fail bridge', error)

      toast('Bridge Failed')
    }
  }, [amount, chainId, provider, refetch])

  if (!account) {
    return (
      <ButtonV3
        onClick={toggleWalletDrawer}
        className="px-5 h-12 bg-white rounded-2xl flex justify-center items-center gap-2.5"
      >
        <span className="text-black text-sm font-medium">Connect Wallet</span>
      </ButtonV3>
    )
  }

  if (isNeedApprove) {
    return (
      <ButtonV3
        isLoading={isSubmitting}
        disabled={isSubmitting}
        onClick={handleSubmit(approveHoldBridge)}
        className="px-5 h-12 bg-white rounded-2xl flex justify-center items-center gap-2.5"
      >
        <span className="text-black text-sm font-medium">Approve</span>
      </ButtonV3>
    )
  }

  return (
    <ButtonV3
      isLoading={isSubmitting}
      disabled={isSubmitting}
      onClick={handleSubmit(bridgeCall)}
      className="px-5 h-12 bg-white rounded-2xl flex justify-center items-center gap-2.5"
    >
      <span className="text-black text-sm font-medium">Confirm</span>
    </ButtonV3>
  )
}
