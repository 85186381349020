import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PAYMASTER_LIST } from './initStore'
import { RateErc20 } from './type'

export interface PayMasterItem {
  address: string
  fixedSender: string
  weth: string
  name: string
  symbol: string
  decimals: string
  decimalsToShow: number
  imageUrl: string
  isEnable: boolean
}

export interface AppearanceSettingType {
  listPaymaster: PayMasterItem[]
  paymasterTokenSelect: PayMasterItem
  rateList?: Record<string, RateErc20>
}

export interface AppearanceSettingsState {
  listPaymaster: PayMasterItem[]
  paymasterTokenSelect: PayMasterItem
  rateList?: Record<string, RateErc20>
}

export const initialAppearanceSettingsState: AppearanceSettingsState = {
  listPaymaster: PAYMASTER_LIST,
  paymasterTokenSelect: PAYMASTER_LIST[11],
  rateList: undefined,
}

const slice = createSlice({
  name: 'paymasterStore',
  initialState: initialAppearanceSettingsState,
  reducers: {
    setPaymasterTokenSelect: (state, action: PayloadAction<PayMasterItem>) => {
      state.paymasterTokenSelect = action.payload
    },
    setPaymasterRateList: (state, action: PayloadAction<Record<string, RateErc20> | undefined>) => {
      state.rateList = action.payload
    },
  },
})

export const { setPaymasterTokenSelect, setPaymasterRateList } = slice.actions

export const paymasterStoreReducer = slice.reducer
