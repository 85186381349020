import clsx from 'clsx'
import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react'

type ButtonProps = {
  children: ReactNode
  isLoading?: boolean
  loadingColor?: string
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export function LoadingV2(props: { className?: string; loadingColor?: string }) {
  const { className = 'w-5 h-5', loadingColor = '#000000' } = props

  return (
    <div
      className={clsx('lds-ring-v2', className)}
      style={
        {
          '--color': loadingColor,
        } as React.CSSProperties
      }
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export function ButtonV3({ children, isLoading, className, disabled, loadingColor, ...props }: ButtonProps) {
  return (
    <button
      {...props}
      disabled={disabled}
      className={clsx(className, {
        'opacity-30': disabled,
      })}
    >
      {isLoading ? <LoadingV2 loadingColor={loadingColor} /> : children}
    </button>
  )
}
