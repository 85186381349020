import dropdown from 'assets/images/dropdown_v2.svg'
import Zksync from 'assets/png/Zksync.png'
import bera from 'assets/png/bera.png'
import asset from 'assets/svg/asset.svg'
import history from 'assets/svg/history.svg'
import hold from 'assets/svg/hold.svg'
import swapWhite from 'assets/svg/swap-white.svg'
import time from 'assets/svg/time.svg'
import clsx from 'clsx'
import { ButtonV3 } from 'components/Button/ButtonV3'
import { dataValue } from './data'

export function Bridge() {
  return (
    <div
      className={clsx(
        'p-5 bg-[#0d0d0d] rounded-2xl border border-[#1f1f1f]',
        'flex flex-col gap-4  overflow-hidden my-10'
      )}
    >
      <div className="flex justify-between items-center">
        <h2 className="text-white text-2xl font-medium">Bridge</h2>

        <div className=" flex justify-center items-center gap-1 px-2 py-1 bg-[#1f1f1f] rounded-full">
          <div className="w-5 h-5 relative">
            <img src={history} alt="history" width={20} height={20} loading="lazy" className="w-full h-full" />
          </div>

          <p className="text-white text-sm">History</p>
        </div>
      </div>

      <div className="flex flex-col gap-3">
        <div className="bg-[#141414] rounded-2xl flex items-center gap-6 overflow-hidden">
          <div className="p-5 bg-[#141414] flex items-center gap-3">
            <div className="w-11 h-11">
              <img src={Zksync} className="w-full h-full object-cover" alt="Zksync" width={20} height={20} />
            </div>

            <div className="px-0.5 flex flex-col gap-1">
              <p className="text-[#808080] text-sm">From</p>
              <p className="text-white text-base font-medium">ZKSync Era</p>
            </div>
          </div>

          <button className="w-9 h-9 p-2 bg-[#191919] rounded-xl border border-[#1f1f1f] flex justify-center items-center gap-2.5">
            <img src={swapWhite} alt="swapWhite" width={20} height={20} loading="lazy" />
          </button>

          <div className="p-5 bg-[#141414] justify-end items-center gap-3 flex">
            <div className="px-0.5 flex items-end flex-col gap-1">
              <p className="text-[#808080] text-sm">To</p>
              <p className="text-white text-base font-medium">BeraChain</p>
            </div>

            <div className="w-11 h-11">
              <img src={bera} className="w-full h-full object-cover" alt="bera" width={20} height={20} />
            </div>
          </div>
        </div>

        <div className="p-5 bg-[#141414] rounded-2xl flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <p className="text-[#808080] text-sm">Amount</p>

            <div className="flex items-center justify-between gap-2">
              <div className="flex-1">
                <input
                  type="text"
                  className="w-full text-white text-2xl font-medium outline-none bg-transparent caret-[#B9A9FB]"
                  placeholder="0"
                />
              </div>

              <div className="px-2 py-1 bg-[#191919] rounded-full flex items-center gap-1.5">
                <p className="text-white text-base font-medium">HOLD</p>

                <div className="w-4 h-4 overflow-hidden">
                  <img src={hold} alt="hold" width={40} height={40} loading="lazy" className="w-full h-full" />
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <p className="text-[#808080] text-xs font-medium leading-[18px]">~ $38,441.76</p>

              <div className="flex items-center gap-1">
                <div className="w-3.5 h-3.5 overflow-hidden">
                  <img src={asset} alt="asset" width={14} height={14} loading="lazy" className="w-full h-full" />
                </div>

                <p className="text-[#808080] text-xs leading-[18px]">Balance: 32322 HOLD</p>
              </div>
            </div>
          </div>

          <div className="flex items-center gap-2">
            {dataValue.map((item, idx) => {
              return (
                <button
                  key={idx}
                  className="flex-1 h-10 px-4 py-2.5 bg-[#1f1f1f] rounded-xl flex justify-center items-center gap-2.5"
                >
                  <span className="text-center text-white text-sm">{item.title}</span>
                </button>
              )
            })}
          </div>
        </div>
      </div>

      <div className="w-full h-[1px] bg-[#191919]" />

      <div className="p-5 bg-[#141414] rounded-2xl flex flex-col gap-3">
        <div className="flex flex-col gap-4">
          <h2 className="text-white text-base font-medium">Receive on Berachain</h2>

          <div className="flex justify-between items-center">
            <div className="flex items-center gap-4">
              <div className="w-10 h-10">
                <img src={hold} className="w-full h-full object-cover" alt="Zksync" width={40} height={40} />
              </div>

              <div className="flex flex-col">
                <div className="text-white text-2xl font-medium">12</div>
                <div className="text-[#808080] text-xs font-medium leading-[18px]">~ $38,441.76</div>
              </div>
            </div>

            <div className="px-2 h-7 bg-[#1f1f1f] rounded-full flex items-center gap-1.5">
              <div className="w-4 h-4 relative  overflow-hidden">
                <img src={time} alt="time" width={16} height={16} loading="lazy" />
              </div>

              <div className="justify-start items-center gap-1 flex">
                <p className="text-[#808080] text-sm font-normal leading-tight">Est</p>
                <p className="text-white text-sm font-medium leading-tight">4m</p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <div className="flex justify-between items-center">
            <p className="text-[#b2b2b2] text-sm">Withholding Fee</p>
            <p className="text-white text-sm">0.001</p>
          </div>

          <div className="flex justify-between items-center">
            <p className="text-[#b2b2b2] text-sm">Est. Receive Amount</p>
            <p className="text-white text-sm">12 HOLD</p>
          </div>
        </div>

        <div className="bg-[#1f1f1f] w-full h-[1px]" />

        <div className="flex justify-between items-center">
          <p className="text-[#b2b2b2] text-sm">Est. Gas</p>

          <div className="pl-3 pr-2 py-1 bg-[#1f1f1f] rounded-full flex items-center gap-1.5">
            <p className="text-white text-base font-medium">0.002</p>

            <div className="w-4 h-4">
              <img src={hold} className="w-full h-full object-cover" alt="hold" width={40} height={40} />
            </div>

            <div className="w-4 h-4">
              <img src={dropdown} className="w-full h-full object-cover" alt="dropdown" width={20} height={20} />
            </div>
          </div>
        </div>
      </div>

      <ButtonV3 className="px-5 h-12 bg-white rounded-2xl flex justify-center items-center gap-2.5">
        <span className="text-black text-sm font-medium">Connect Wallet</span>
      </ButtonV3>
    </div>
  )
}
