import { FeeAmount } from '@uniswap/v3-sdk'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import { LogoImage } from 'pages/Pool/all-pool'
import { memo, useCallback, useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { formatDisplay } from 'utils/formatNumbers'
import { getSymbol } from './Transaction'
import { usePoolDetails } from './datav2'

function Header() {
  const { id } = useParams<{ id?: string }>()
  const { data: pools, loading } = usePoolDetails(id ?? '')
  const navigation = useNavigate()

  const { criteria1, criteria2 } = useMemo(() => {
    if (!pools?.[id ?? '']) {
      return { criteria1: '--', criteria2: '--' }
    }

    const pool = pools[id ?? '']

    return {
      criteria1: formatDisplay(pool.token1Price, { decimalToShow: 4 }),
      criteria2: formatDisplay(pool.token0Price, { decimalToShow: 4 }),
    }
  }, [id, pools])

  const { address1, address2, symbol1, symbol2, feesUSD } = useMemo(() => {
    if (!pools?.[id ?? '']) {
      return {
        address1: '',
        address2: '',
        symbol1: '',
        symbol2: '',
        feesUSD: 0,
      }
    }

    const pool = pools[id ?? '']

    return {
      address1: pool.token0.address,
      address2: pool.token1.address,
      symbol1: pool.token0.symbol === 'WETH' ? 'ETH' : pool.token0.symbol,
      symbol2: pool.token1.symbol === 'WETH' ? 'ETH' : pool.token1.symbol,
      feesUSD: new BigNumber(pool.feeTier).dividedBy(10_000).toNumber(),
    }
  }, [id, pools])

  const navigate = useCallback(() => {
    return navigation(`/add?currentA=${address1}&currentB=${address2}`, {
      state: {
        currencyA: address1,
        currencyB: address2,
        feeAmount: pools?.[id ?? ''].feeTier ?? FeeAmount.MEDIUM,
        tokenId: id ?? '',
      },
    })
  }, [navigation, address1, address2, pools, id])

  if (loading) {
    return (
      <div className="mb-4 flex items-center gap-2 h-8">
        <Skeleton width={32} height={24} baseColor="#202020" highlightColor="#444" borderRadius={12} />
        <Skeleton width={120} height={32} baseColor="#202020" highlightColor="#444" className="rounded-full" />
        <Skeleton width={32} height={28} baseColor="#202020" highlightColor="#444" borderRadius={12} />
      </div>
    )
  }

  return (
    <div>
      <div className="flex items-center gap-x-2 mb-4 ml-3">
        <LogoImage id0={address1} id1={address2} />

        <span className="truncate text-2xl">{`${getSymbol(symbol1)}/${getSymbol(symbol2)}`}</span>

        <div
          className={clsx(
            'border border-[#242424] rounded-full  px-2 py-1 flex items-center justify-center',
            'text-sm text-[#4C4C4C]',
            {
              hidden: !feesUSD,
            }
          )}
        >
          {formatDisplay(feesUSD, { decimalToShow: 2 })}%
        </div>
      </div>

      <div className="mb-8 flex flex-col md:flex-row items-start md:items-center gap-y-6 justify-between">
        <div className="flex items-center gap-2.5">
          <div className="px-3 py-2 rounded-xl bg-[#1C1C1C]">
            <p className="text-base">
              1 {symbol1} = {criteria1} {symbol2}
            </p>
          </div>

          <div className="px-3 py-2 rounded-xl bg-[#1C1C1C]">
            <p className="text-base">
              1 {symbol2} = {criteria2} {symbol1}
            </p>
          </div>
        </div>

        <div className="flex items-center gap-2.5">
          <button onClick={navigate} className="px-3 py-2 rounded-xl bg-[#1C1C1C]">
            <p className="text-base">Add Liquidity</p>
          </button>

          <Link
            to={`/swap?inputCurrency=${address1}&outputcurrency=${address2}`}
            className="px-3 py-2 rounded-xl bg-[#DBFF73]"
          >
            <p className="text-base text-black">Trade</p>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default memo(Header)
