import Zksync from 'assets/png/Zksync.png'
import bera from 'assets/png/bera.png'
import history from 'assets/svg/history.svg'
import clsx from 'clsx'
import { FormProvider, useForm } from 'react-hook-form'
import { Balance } from './balance'
import { Button } from './button'
import { Chain } from './chain'
import { EstimateReceive } from './estimate'

export interface BrigeForm {
  active: number
  value: string
  from: {
    chainId: number
    name: string
    img: string
  }
  to: {
    name: string
    chainId: number
    img: string
  }
}

export function Bridge() {
  const methods = useForm<BrigeForm>({
    defaultValues: {
      active: 0,
      value: '',
      from: {
        chainId: 324,
        name: 'ZKSync Era',
        img: Zksync,
      },
      to: {
        chainId: 80084,
        name: 'BeraChain',
        img: bera,
      },
    },
    mode: 'all',
  })

  return (
    <FormProvider {...methods}>
      <div
        className={clsx(
          'p-5 bg-[#0d0d0d] rounded-2xl border border-[#1f1f1f]',
          'flex flex-col gap-4 overflow-hidden lg:my-10'
        )}
      >
        <div className="flex justify-between items-center">
          <h2 className="text-white text-2xl font-medium">Bridge</h2>

          <div className=" flex justify-center items-center gap-1 px-2 py-1 bg-[#1f1f1f] rounded-full">
            <div className="w-5 h-5 relative">
              <img src={history} alt="history" width={20} height={20} loading="lazy" className="w-full h-full" />
            </div>

            <p className="text-white text-sm">History</p>
          </div>
        </div>

        <div className="flex flex-col gap-3">
          <Chain />

          <Balance />
        </div>

        <div className="w-full h-[1px] bg-[#191919]" />

        <EstimateReceive />

        <Button />
      </div>
    </FormProvider>
  )
}
