import Zksync from 'assets/png/Zksync.png'
import bera from 'assets/png/bera.png'
import swapWhite from 'assets/svg/swap-white.svg'
import { useCallback } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { BrigeForm } from './bridge'

export function Chain() {
  const { control, setValue } = useFormContext<BrigeForm>()

  const [from, to] = useWatch({ control, name: ['from', 'to'] })

  const switchBridge = useCallback(() => {
    if (from.chainId === 324) {
      setValue('from', {
        chainId: 80084,
        name: 'BeraChain',
        img: bera,
      })

      setValue('to', {
        chainId: 324,
        name: 'ZKSync Era',
        img: Zksync,
      })
      return
    }

    setValue('from', {
      chainId: 324,
      name: 'ZKSync Era',
      img: Zksync,
    })

    setValue('to', {
      chainId: 80084,
      name: 'BeraChain',
      img: bera,
    })
  }, [from.chainId, setValue])

  return (
    <div className="relative bg-[#141414] rounded-2xl flex flex-col lg:flex-row lg:items-center lg:gap-6 overflow-hidden">
      <div className="p-4 lg:p-5 bg-[#141414] flex items-center gap-3">
        <div className="w-10 lg:w-11 h-10 lg:h-11">
          <img src={from.img} className="w-full h-full object-cover" alt="Zksync" width={20} height={20} />
        </div>

        <div className="px-0.5 flex flex-col gap-1">
          <p className="text-[#808080] text-sm">From</p>
          <p className="text-white text-base font-medium">{from.name}</p>
        </div>
      </div>

      <button
        onClick={switchBridge}
        className="absolute right-6 lg:right-auto top-1/2 lg:top-auto -translate-y-1/2 lg:translate-y-0 lg:relative  w-9 h-9 p-2 bg-[#191919] rounded-xl border border-[#1f1f1f] flex justify-center items-center gap-2.5"
      >
        <img src={swapWhite} alt="swapWhite" width={20} height={20} loading="lazy" />
      </button>

      <div className="p-4 lg:p-5 bg-[#141414] justify-end items-center gap-3 flex flex-row-reverse lg:flex-row">
        <div className="px-0.5 flex lg:items-end flex-col gap-1">
          <p className="text-[#808080] text-sm">To</p>
          <p className="text-white text-base font-medium">{to.name}</p>
        </div>

        <div className="w-10 lg:w-11 h-10 lg:h-11">
          <img src={to.img} className="w-full h-full object-cover" alt="bera" width={20} height={20} />
        </div>
      </div>
    </div>
  )
}
