import { useWeb3React } from '@web3-react/core'
import { APP_RPC_URLS } from 'constants/networks'
import { ethers } from 'ethers'
import { useQuery } from 'react-query'
import { Erc20__factory } from 'uniswap/src/abis/types'
import { ZKSYNC_OFT } from './constant'

export const dataValue = [
  { title: '25%', value: 0.25 },
  { title: '50%', value: 0.5 },
  { title: '75%', value: 0.75 },
  { title: '100%', value: 1 },
]

const HOLD = '0xed4040fd47629e7c8fbb7da76bb50b3e7695f0f2'

async function fetcher(account?: string) {
  if (!account) {
    return undefined
  }
  // ZKSYNC_OFT.oft
  const provider = new ethers.providers.JsonRpcProvider(APP_RPC_URLS[324][0])

  const contract = Erc20__factory.connect(ZKSYNC_OFT.hold, provider)
  const contractHold = Erc20__factory.connect(HOLD, provider)

  const data = await Promise.all([contractHold.balanceOf(account), contract.allowance(account, ZKSYNC_OFT.oft)])

  return data
}

export function useBalance() {
  const { account } = useWeb3React()

  const data = useQuery(['balance-token', account], () => fetcher(account), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity,
    cacheTime: 60000,
  })

  return data
}

//
// Estimate only using for bridge
//

export function useEstimateGas() {
  const { account } = useWeb3React()

  const data = useQuery(['balance-token', account], () => fetcher(account), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity,
    cacheTime: 60000,
  })

  return data
}
